import {createSlice} from '@reduxjs/toolkit'

const GlobalSlice = createSlice(
    {
        name: 'global',
        initialState: {
            user: {
                id: 0,
                email: "",
                paid: null,
                authenticated: null,
                firstName: null,
                userId: null,
                stripeCustomerId: null,
                stripeSubscriptionId: null,
                stripePriceId: null,
                stripeClientSecret: null,
                stripeSubscriptionStatus: null,
                stripeTrialStart: null,
                stripeTrialEnd: null,
                stripeCancelAt: null,
                stripeCanceledAt: null,
                subscribed: null
            },
            remember: true,
            loginModal: false,
            pricingModal: false,
        },
        reducers: {
            setUser: (state, action) => {
                state.user = action.payload
            },
            setLoginModal: (state, action) => {
                state.loginModal = action.payload
            },
            setPriceId: (state, action) => {
                state.user.stripePriceId = action.payload
            },
            setPricingModal: (state, action) => {
                state.pricingModal = action.payload
            },
            setRemember: (state, action) => {
                state.remember = action.payload
            },
            resetUser: (state, action) => {
                state.user = {
                    id: 0,
                    email: "",
                    paid: null,
                    authenticated: false,
                    firstName: null,
                    userId: null,
                    stripeCustomerId: null,
                    stripeSubscriptionId: null,
                    stripePriceId: null,
                    stripeClientSecret: null,
                    stripeSubscriptionStatus: null,
                    stripeTrialStart: null,
                    stripeTrialEnd: null,
                    stripeCancelAt: null,
                    stripeCanceledAt: null,
                    subscribed: false
                }
            }
        }
    }
)

export const {
    setUser,
    setPriceId,
    setLoginModal,
    setPricingModal,
    setRemember,
    resetUser
} = GlobalSlice.actions

export default GlobalSlice.reducer