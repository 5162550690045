import React from 'react'
import CssBaseline from "@mui/material/CssBaseline"
import Header from "../../global/Header"
import Footer from "../../global/Footer"
import Routes from "../../../config/routes"
import Grid from '@mui/material/Grid'
import Box from "@mui/material/Box"


function Main() {

    return (
        <>
            <CssBaseline>
                <Grid container>
                    <Header/>
                </Grid>
                <Box sx={{marginTop: "64px", minHeight: "400px", width: "100%"}}>
                    <Routes/>
                </Box>
                <Footer />
            </CssBaseline>
        </>
    )
}

export default Main
