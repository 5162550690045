import {createSlice} from "@reduxjs/toolkit"


const DiscountSlice = createSlice({
    name: "discount",
    initialState: {
        email: "",
        password: "",
        rememberMe: true,
        errors: {
            emailError: null,
            passwordError: null,
            formError: null
        },
        showPassword: false,
        openAlert: false
    },
    reducers: {
        setEmail: (state, action) => {
            state.openAlert = false
            state.email = action.payload
        },
        setPassword: (state, action) => {
            state.openAlert = false
            state.password = action.payload
        },
        setError: (state, action) => {
            state.errors = action.payload
        },
        setAlertOpen: (state, action) => {
            state.openAlert = action.payload
        },
        setShowPassword: (state, action) => {
            state.showPassword = action.payload
        },
        clearMessages: (state, action) => {
            state.messages = []
        },
        setCouponValid (state, action) {
          state.isCouponValid = action.payload
        },
        setCouponCode: (state, action) => {
          state.couponCode = action.payload
        },


    }
})

export const {
    setEmail,
    setPassword,
    setError,
    setAlertOpen,
    setShowPassword,
    clearMessages,
    setCouponValid,
    setCouponCode
} = DiscountSlice.actions

export default DiscountSlice.reducer