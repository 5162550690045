import {createSlice} from "@reduxjs/toolkit"

const ResetPasswordSlice = createSlice({
    name: 'resetPassword',
    initialState: {
        openAlert: false,
        newPassword1: '',
        newPassword2: '',
        showPassword1: false,
        showPassword2: false,
        messages: []
    },
    reducers: {
        setPassword1: (state, action) => {
            state.newPassword1 = action.payload
        },
        setPassword2: (state, action) => {
            state.newPassword2 = action.payload
        },
        setShowPassword1: (state, action) => {
            state.showPassword1 = action.payload
        },
        setShowPassword2: (state, action) => {
            state.showPassword2 = action.payload
        },

        setAlertOpen: (state, action) => {
            state.openAlert = action.payload
        },
        addMessage: (state, action) => {
            state.messages.push(action.payload)
        },
        clearMessages: (state, action) => {
            state.messages = []
        }

    }
})

export const {
    setAlertOpen,
    addMessage,
    clearMessages,
    setPassword1,
    setPassword2,
    setShowPassword1,
    setShowPassword2,
} = ResetPasswordSlice.actions

export default ResetPasswordSlice.reducer