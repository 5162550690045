import {createSlice} from "@reduxjs/toolkit"

const ForgotPasswordSlice = createSlice({
    name: 'forgotPassword',
    initialState: {
        openAlert: false,
        email: '',
        messages: []
    },
    reducers: {
        setEmail: (state, action) => {
            state.email = action.payload
        },
        setAlertOpen: (state, action) => {
            state.openAlert = action.payload
        },
        addMessage: (state, action) => {
            state.messages.push(action.payload)
        },
        clearMessages: (state, action) => {
            state.messages = []
        }

    }
})

export const {
    setAlertOpen,
    addMessage,
    setEmail,
    clearMessages
} = ForgotPasswordSlice.actions

export default ForgotPasswordSlice.reducer