import React, {useState} from 'react'
import Cookies from 'universal-cookie'
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import {useDispatch, useSelector} from "react-redux"
import {setPassword, setEmail, setError, setAlertOpen, setShowPassword, clearMessages} from "./slice"
import {Link, useLocation, useNavigate} from "react-router-dom"
import {LOGIN_MUTATION} from './graphql'
import {useMutation} from "@apollo/client"
import Alert from "@mui/material/Alert"
import {
    AUTH_TOKEN,
    STRIPE_CUSTOMER_ID,
    STRIPE_SUBSCRIPTION_ID,
    USER_ID
} from "../../../config/constants"
import Fade from '@mui/material/Fade'
import {InputAdornment} from "@mui/material"
import IconButton from "@mui/material/IconButton"
import {Visibility, VisibilityOff} from "@mui/icons-material"
import {setUser, setLoginModal, setRemember, resetUser} from "../../../slice"
import Seo from "../../html/Seo"
import {handleUserLogout} from "../RefreshToken"
import Typography from "@mui/material/Typography"

function Login() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const [openResetPassword, setOpenResetPassword] = useState(true)
    const authenticated = useSelector(state => state.global.user.authenticated)
    const email = useSelector(state => state.login.email)
    const password = useSelector(state => state.login.password)
    const alert = useSelector(state => state.login.errors.formError)
    const showPassword = useSelector(state => state.login.showPassword)
    const openAlert = useSelector(state => state.login.openAlert)
    const remember = useSelector(state => state.global.remember)

    const cookies = new Cookies()

    const handleErrors = (errors) => {
        for (const [index, error] of Object.entries(errors)) {
            if (index === 'nonFieldErrors') {
                for (const errorCode of error) {
                    if (errorCode.code === 'invalid_credentials') {
                        dispatch(setError({formError: "Email or Password incorrect."}))
                        dispatch(setAlertOpen(true))
                    }
                }

            }
        }
    }

    const handleLogout = () => {
        handleUserLogout()
        dispatch(resetUser())
        navigate('/')
    }

    const [getLogin, {loading}] = useMutation(LOGIN_MUTATION, {
        onCompleted: ({authentication}) => {
            if (!authentication.errors) {
                const stripeCustomerId = authentication.user.stripeCustomerId
                const stripeSubscriptionId = authentication.user.stripeSubscriptionId
                const stripePriceId = authentication.user.stripePriceId
                const userId = authentication.user.id

                const date = new Date()

                if (remember) {
                    cookies.set('bprf', authentication.refreshToken, {
                        // httpOnly: false,
                        path: '/',
                        // secure: true,
                        expires: new Date(date.getTime() + (30 * 24 * 60 * 60 * 1000))
                    })
                }

                sessionStorage.setItem(AUTH_TOKEN, authentication.token)

                // localStorage.setItem(FIRST_NAME, authentication.user.firstName)
                if (stripeCustomerId) {
                    localStorage.setItem(STRIPE_CUSTOMER_ID, stripeCustomerId)
                }
                if (stripeSubscriptionId) {
                    sessionStorage.setItem(STRIPE_SUBSCRIPTION_ID, stripeSubscriptionId)
                }

                localStorage.setItem(USER_ID, userId)
                dispatch(setUser({
                    authenticated: true,
                    email: authentication.user.email,
                    username: authentication.user.username,
                    subscribed: authentication.subscribed,
                    paid: authentication.paid,
                    expiresFromNow: authentication.expiresFromNow,
                    stripeCustomerId,
                    stripePriceId,
                    stripeSubscriptionId
                }))
                if (location.pathname.toLowerCase() === '/login') {
                    navigate('/')
                } else {
                    dispatch(setLoginModal(false))
                }

            } else {
                dispatch(setAlertOpen(true))
                dispatch(setError({formError: 'Email or Password incorrect.'}))
                handleErrors(authentication.errors)
            }
        }
    })

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    const handleLogin = async (e) => {
        e.preventDefault()

        await getLogin({
            variables: {
                email,
                password
            }
        })

        return false
    }

    return (
        <Grid container>
            <Seo title={'Login'}/>
            {!authenticated ?
                <Grid item xs={12}>
                    <Box sx={{textAlign: "center", width: "100%"}} mt={1}>
                        <Typography variant='h2'>Login</Typography>
                    </Box>
                    <Fade in={openAlert}>
                        <Box sx={{maxWidth: '500px'}} mx='auto'>
                            <Alert onClose={() => {
                                dispatch(setAlertOpen(false))
                                dispatch(clearMessages())
                            }} severity='error'>
                                {alert}
                            </Alert>

                        </Box>
                    </Fade>
                    <Box sx={{maxWidth: {xs: '90%', md: "500px"}, marginLeft: "auto", marginRight: "auto"}}>
                        <form onSubmit={handleLogin}>
                            <TextField
                                onChange={event => dispatch(setEmail(event.target.value))}
                                disabled={loading}
                                value={email}
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                type='email'
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <TextField
                                onChange={event => dispatch(setPassword(event.target.value))}
                                disabled={loading}
                                value={password}
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                autoComplete="current-password"
                                InputProps={
                                    {
                                        endAdornment: <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={() => {
                                                    dispatch(setShowPassword(!showPassword))
                                                }}
                                                edge='end'
                                            >
                                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                }
                            />
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary" checked={remember}
                                                   onChange={() => {
                                                       dispatch(setRemember(!remember))
                                                   }}
                                />}
                                label="Remember me"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                            >
                                {!loading ? "Login" : "Logging In..."}
                            </Button>
                            <Button
                                variant='text'
                                fullWidth
                                onClick={() => {
                                    dispatch(setLoginModal(false))
                                    navigate('/forgot-password')
                                }}
                            >Forgot Password</Button>
                        </form>
                        <Box mt={3}>
                            <h6>Don't have an account? <Link onClick={() => {
                                dispatch(setLoginModal(false))
                            }} to={'/signup'}>Sign up</Link>.</h6>
                        </Box>
                    </Box>
                </Grid>
                :
                <Grid item xs={12}>
                    <Box sx={{
                        maxWidth: "500px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: 'flex',
                        flexDirection: 'column'
                    }} mt={4}>
                        <h3 style={{textAlign: "center", marginBottom: '20px'}}>You are already logged in.</h3>
                        <Button sx={{margin: 'auto'}} onClick={() => handleLogout()}>
                            Login with a different account.
                        </Button>
                    </Box>
                </Grid>
            }

        </Grid>
    )
}

export default Login