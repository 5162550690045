export const AUTH_TOKEN = 'auth-token'
export const REFRESH_TOKEN = 'refresh-token'
export const EMAIL = 'email'
export const USER_ID = 'user-id'
export const FIRST_NAME = 'first-name'
export const AUTHENTICATED = 'authenticated'
export const STRIPE_CUSTOMER_ID = 'stripe-customer-id'
export const STRIPE_SUBSCRIPTION_ID = 'stripe-subscription-id'
export const STRIPE_CLIENT_SECRET = 'stripe-client-secret'
export const STRIPE_PRICE_ID = 'stripe-price-id'

export const COUPON_CODE = 'stripe-coupon-code'

export const STRIPE_PRODUCT_ID = 'product-id'
export const STRIPE_COUPON_CODE = 'stripe-coupon-code'