import {createTheme} from "@mui/material/styles"

export const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#e22e8d',
        },
        secondary: {
            main: '#01FFE4',
        },
        background: {
            paper: '#eef1f7',
            alt: '#f5f5f5'
        }
    },
    typography: {
        body1: {
            lineHeight: 1.3
        },
        fontFamily: [
            'Brandon',
            '--apple-system',
            "Segoe UI",
            'Roboto',
            "Helvetica Neue",
            'Arial',
            'sans-serif',
            "Apple Color Emoji",
            "Segoe UI Emoji",
            "Segoe UI Symbol",
        ].join(','),
        p: {
            fontFamily: 'Brandon',
        },
        h1: {
            fontSize: '2.9rem',
        },
        h3: {
            fontSize: '1.9rem',
        },
        h4: {
            fontSize: '1.563rem',
        },
        h5: {
            fontSize: '1.563rem',
        },
        h6: {
            fontSize: '1rem',
        },
        fontSize: 16,
        fontWeightRegular: 200,
        fontWeightBold: 600,
    },
    overrides: {
        MuiAppBar: {
            colorInherit: {
                backgroundColor: '#ffffff',
                color: '#fff',
            },
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: "#ffffff"
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        "backgroundColor": " #fff",
                        ".MuiListItemIcon-root": {
                            "color": "#e22e8d"
                        }
                    },
                },

            }
        },
        MuiTabs: {
            styleOverrides: {
                root:{
                    fontSize: '1.5rem'
                }
            }
        }
    },
    props: {
        MuiAppBar: {
            color: 'inherit',
        },
    },
})

theme.typography.h2 = {
    [theme.breakpoints.up('xs')]: {
        fontSize: '2.5rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '3.438rem'
    }
}