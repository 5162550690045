import {createSlice} from "@reduxjs/toolkit"

const ProfileSlice = createSlice({
    name: 'profile',
    initialState: {
        firstName: "",
        lastName: "",
        email: "",
        username: "",
        oldPassword: "",
        newPassword1: "",
        newPassword2: "",
        openAlert: false,
        messages: []
    },
    reducers: {
        setFirstName: (state, action) => {
            state.openAlert = false
            state.firstName = action.payload
        },
        setLastName: (state, action) => {
            state.openAlert = false
            state.lastName = action.payload
        },
        setEmail: (state, action) => {
            state.openAlert = false
            state.email = action.payload
        },
        setUsername: (state, action) => {
            state.openAlert = false
            state.username = action.payload
        },
        setOldPassword: (state, action) => {
            state.oldPassword = action.payload
        },
        setNewPassword1: (state, action) => {
            state.newPassword1 = action.payload
        },
        setNewPassword2: (state, action) => {
            state.newPassword2 = action.payload
        },
        setAlertOpen: (state, action) => {
            state.openAlert = action.payload
        },
        addMessage: (state, action) => {
            state.messages.push(action.payload)
        },
        clearMessages: (state, action) => {
            state.messages = []
        }

    }
})

export const {
    setFirstName,
    setLastName,
    setEmail,
    setAlertOpen,
    addMessage,
    setUsername,
    clearMessages,
    setOldPassword,
    setNewPassword1,
    setNewPassword2,
} = ProfileSlice.actions

export default ProfileSlice.reducer