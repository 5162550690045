import {createSlice} from "@reduxjs/toolkit"


const CommentDialogSlice = createSlice({
    name: "commentDialog",
    initialState: {
        open: false,
        commentId: 0,
    },
    reducers: {
        setOpen: (state, action) => {
            state.open = action.payload
        },
        setCommentId: (state, action) => {
            state.commentId = action.payload
        },

    }
})

export const {
    setOpen,
    setCommentId,
} = CommentDialogSlice.actions

export default CommentDialogSlice.reducer