import {createSlice} from "@reduxjs/toolkit"


const SubscriptionDialogSlice = createSlice({
        name: "subscriptionDialog",
        initialState: {
            open: false,
            actionContents: 'cancel',
            openAlert: false,
            messages: []
        },
        reducers: {
            setOpen: (state, action) => {
                state.open = action.payload
            },
            setActionContents: (state, action) => {
                state.actionContents = action.payload
            },
            setAlertOpen: (state, action) => {
                state.openAlert = action.payload
            },
            addMessage: (state, action) => {
                state.messages.push(action.payload)
            },
        }
    }
)

export const {
    setOpen,
    setAlertOpen,
    setActionContents,
    addMessage
} = SubscriptionDialogSlice.actions

export default SubscriptionDialogSlice.reducer