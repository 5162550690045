import {gql} from '@apollo/client'

export const GET_SELF = gql`
    query get_self {
        getSelf {
            id
            username
            email
            paid
            subscriptionStatus
            stripeCustomerId
            stripePriceId
            stripeSubscriptionId
            stripeTrialStart
            stripeTrialEnd
            stripePeriodStart
            stripePeriodEnd
            stripeCancelAt
            stripeCanceledAt
            subscribed
        }
    }
`

export const REFRESH_TOKEN = gql`
    mutation refresh_token ($refreshToken: String!) {
        refreshToken(refreshToken: $refreshToken) {
            token
            payload
            refreshToken
            success
            errors
            expireFromNow
            subscribed
            paid
        }
    }
`