import {configureStore} from "@reduxjs/toolkit"
import LoginSlice from "./components/auth/Login/slice"
import SignupSlice from "./components/auth/Signup/slice"
import DrawerSlice from './components/scaffolding/Videos/Drawer/slice'
import SearchBarSlice from './components/scaffolding/Videos/SearchBar/slice'
import ProfileSlice from './components/auth/Account/Profile/slice'
import CommentDialogSlice from './components/comments/CommentDialog/slice'
import SubscriptionDialogSlice from './components/auth/Account/Subscriptions/SubscriptionDialog/slice'
import GlobalSlice from './slice'
import ProductDialogSlice from './components/product/ProductDialog/slice'
import GlobalModalSlice from './components/global/GlobalModal/slice'
import ForgotPasswordSlice from './components/auth/ForgotPassword/slice'
import ResetPasswordSlice from './components/auth/ResetPassword/slice'
import MessageBarSlice from './components/global/MessageBar/slice'
import ProductItemSlice from  './components/product/ProductItem/slice'
import ContactSlice from './components/pages/Contact/slice'
import DiscountSlice from './components/auth/Discount/slice'

export default configureStore({
    reducer: {
        login: LoginSlice,
        commentDialog: CommentDialogSlice,
        subscriptionDialog: SubscriptionDialogSlice,
        productDialog: ProductDialogSlice,
        signup: SignupSlice,
        profile: ProfileSlice,
        drawer: DrawerSlice,
        searchBar: SearchBarSlice,
        global: GlobalSlice,
        globalModal: GlobalModalSlice,
        forgotPassword: ForgotPasswordSlice,
        resetPassword: ResetPasswordSlice,
        messageBar: MessageBarSlice,
        productItem: ProductItemSlice,
        contact: ContactSlice,
        discount: DiscountSlice
    },
})
