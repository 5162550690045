import React from 'react'
import Autocomplete from "@mui/material/Autocomplete";
import {InputAdornment, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useLazyQuery} from "@apollo/client";
import {GET_AUTOCOMPLETE} from "../SearchBar/graphql";
import {setAutocomplete, setAutocompleteOptions} from "../SearchBar/slice"
import TuneIcon from '@mui/icons-material/Tune';
import {setDrawerOpen} from "../Drawer/slice";

function SearchInput (props) {

    const {size} = props
    const navigate = useNavigate()
    const autoCompleteOptions = useSelector(state => state.searchBar.autoCompleteOptions)
    const searchText = useSelector(state => state.searchBar.autocomplete)
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()

    const autocompleteSelectMap = {
        'Instructor': 'instructors',
        'Full Class': 'videos',
        'Technique': 'videos',
        'Live': 'videos'
    }

    const [getAutocomplete] = useLazyQuery(GET_AUTOCOMPLETE,
        {
            onCompleted: ({autocomplete}) => {
                dispatch(setAutocompleteOptions(autocomplete.map(item => (
                    {
                        title: item.title,
                        type: item.type,
                        id: item.id
                    }
                ))))
            },
            fetchPolicy: 'no-cache'

        }
    )

    const getSearchResults = (event) => {
        const search = event.currentTarget.value
        dispatch(setAutocomplete(search))

        if (search.length > 1) {
            getAutocomplete({
                variables: {
                    q: search
                }
            })
        } else {
            dispatch(setAutocompleteOptions([]))
        }
    }

    const handleAutocompleteSelection = (event, value) => {
        searchParams.delete('pageNum')
        searchParams.delete('instructors')
        searchParams.delete('techniques')
        searchParams.delete('pageNum')
        searchParams.delete('categories')
        if (typeof value.type !== 'undefined') {
            dispatch(setAutocompleteOptions([]))
            const type = value['type']
            // if (type.toLowerCase() === 'technique') {
            //     deleteSearchParams()
            //     searchParams.set('techniques', value.id)
            //     navigate({
            //         pathname: '/search',
            //         search: `?${searchParams.toString()}`
            //     })
            //     // setSearchParams(searchParams)
            // } else {
                navigate(`/${autocompleteSelectMap[value.type]}/${value.id}`)
            //}
        }

    }

    const deleteSearchParams = () => {
        const tempParams = Object.assign(searchParams)
        tempParams.forEach((value, key) => {
            searchParams.delete(key)
        })
    }

    const handleKeyPress = (event) => {
        const value = event.target.value
        if (event.code.toLowerCase() === 'enter' && value) {
            deleteSearchParams()
            dispatch(setAutocomplete(value))
            searchParams.delete('pageNum')
            searchParams.delete('instructors')
            searchParams.delete('techniques')
            searchParams.delete('pageNum')
            searchParams.delete('categories')
            searchParams.set('q', value)
            navigate({
                pathname: '/search',
                search: `?${searchParams.toString()}`
            })
            // setSearchParams(searchParams)
        }
    }

    return(
                <Autocomplete
                    sx={{
                        width: '100%',
                        bgcolor: 'transparent',
                    }}
                    freeSolo
                    filterOptions={(x) => x}
                    groupBy={option => option.type}
                    getOptionLabel={option => option.title || searchText}
                    onChange={(event, value) => {
                        handleAutocompleteSelection(event, value)
                    }}
                    renderInput={params => (
                        <TextField
                            {...params}
                            value={searchText}
                            onChange={getSearchResults}
                            onKeyDown={handleKeyPress}
                            placeholder='Search'
                            label=''
                            size={(size) ? size : 'medium'}
                            sx={{
                                boxShadow: 'none',
                            }}
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                                endAdornment: (
                                    <InputAdornment position={"end"} sx={{display: {md: 'none'}}}>
                                        <TuneIcon
                                            onClick={() => {dispatch(setDrawerOpen(true))}}
                                        />
                                    </InputAdornment>
                                ),
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <SearchIcon/>
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                    options={autoCompleteOptions}
                />)
}

export default SearchInput