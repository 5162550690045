import {createSlice} from "@reduxjs/toolkit"

const GlobalModalSlice = createSlice({
        name: 'GlobalModal',
        initialState: {
            open: false
        },
        reducers: {
            setModalOpen: (state, action) => {
                state.open = action.payload
            }
        }
    }
)

export const {
    setModalOpen
} = GlobalModalSlice.actions

export default GlobalModalSlice.reducer