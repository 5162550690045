import {createSlice} from '@reduxjs/toolkit'

const SearchBarSlice = createSlice({
    name: 'searchBar',
    initialState: {
        page: 0,
        class: [],
        autocomplete: '',
        autoCompleteOptions: [],
    },
    reducers: {
        setPage: (state, action) => {
            state.page = action.payload
        },
        setAutocomplete: (state, action) => {
            state.autocomplete = action.payload
        },
        setAutocompleteOptions: (state, action) => {
            state.autoCompleteOptions = action.payload
        }
    }
})

export const {
    setPage,
    setAutocomplete,
    setAutocompleteOptions
} = SearchBarSlice.actions

export default SearchBarSlice.reducer