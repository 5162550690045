import {createSlice} from "@reduxjs/toolkit"

const SignupSlice = createSlice({
    name: "signup",
    initialState: {
        couponCode: "",
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
        rememberMe: true,
        errorMessages: [],
        openAlert: false,
        isCouponValid: false
    },
    reducers: {
        setCouponCode: (state, action) => {
          state.couponCode = action.payload
        },
        setUsername: (state, action) => {
            state.username = action.payload
        },
        setEmail: (state, action) => {
            state.email = action.payload
        },
        setPassword: (state, action) => {
            state.password = action.payload
        },
        setConfirm: (state, action) => {
            state.confirmPassword = action.payload
        },
        setAlertOpen: (state, action) => {
            state.openAlert = action.payload
        },
        setCouponValid (state, action) {
          state.isCouponValid = action.payload
        },
        addErrorMessage: (state, action) => {
            state.errorMessages.push(action.payload)
        },
        clearErrorMessage: (state, action) => {
            state.errorMessages = []
        }
    }
})

export const {
    setCouponCode,
    setEmail,
    setUsername,
    setPassword,
    setConfirm,
    setAlertOpen,
    addErrorMessage,
    setCouponValid,
    clearErrorMessage
} = SignupSlice.actions

export default SignupSlice.reducer