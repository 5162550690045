import {createSlice} from "@reduxjs/toolkit"


const ProductItemSlice = createSlice({
    name: "productItem",
    initialState: {
       loading: false
    },
    reducers: {
        setProductLoadilng: (state, action) => {
            state.loading = action.payload
        },
    }
})

export const {
    setProductLoadilng,

} = ProductItemSlice.actions

export default ProductItemSlice.reducer