import React, {useState, forwardRef, useEffect} from 'react'
import {useLazyQuery, useMutation} from "@apollo/client"
import {GET_SELF, REFRESH_TOKEN} from "../../global/Header/graphql"
import {
    AUTH_TOKEN,
    AUTHENTICATED,
    FIRST_NAME,
    STRIPE_CLIENT_SECRET,
    STRIPE_CUSTOMER_ID,
    STRIPE_PRICE_ID,
    STRIPE_SUBSCRIPTION_ID,
    USER_ID
} from "../../../config/constants"
import Cookies from "universal-cookie"
import {setUser, resetUser} from "../../../slice"
import {useDispatch, useSelector} from "react-redux"
import Snackbar from "@mui/material/Snackbar"
import MuiAlert from '@mui/material/Alert'

let refreshTimer = null

export const cancelRefresh = () => {
    if (refreshTimer) {
        clearInterval(refreshTimer)
    }
}

export const removeCookie = () => {
    const cookies = new Cookies()
    cookies.remove('bprf')
}

export const removeLocalStorage = () => {
    sessionStorage.removeItem(AUTH_TOKEN)
    localStorage.removeItem(FIRST_NAME)
    localStorage.removeItem(USER_ID)
    localStorage.removeItem(AUTHENTICATED)
    localStorage.removeItem(STRIPE_CUSTOMER_ID)
    localStorage.removeItem(STRIPE_SUBSCRIPTION_ID)
    localStorage.removeItem(STRIPE_PRICE_ID)
    localStorage.removeItem(STRIPE_CLIENT_SECRET)
}

export const handleUserLogout = () => {
    cancelRefresh()
    removeCookie()
    removeLocalStorage()
}

function RefreshToken() {
    const cookies = new Cookies()
    const refreshTokenCookie = cookies.get('bprf')
    const dispatch = useDispatch()
    const user = useSelector(state => state.global.user)
    const [snackState, setSnackState] = useState({open: false, message: ''})

    const {open, message} = snackState

    const handleSnackClose = () => {
        setSnackState({open: false, message: ''})
    }

    const Alert = forwardRef(
        function Alert(props, ref,) {
            return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
        })

    const [getRefreshToken] = useMutation(REFRESH_TOKEN, {
        variables: {
            refreshToken: refreshTokenCookie
        },
        onError: () => {
            setSnackState({open: true, message: 'Your session has ended. Please login again.'})
            handleUserLogout()
        },
        onCompleted: ({refreshToken}) => {
            if (!refreshToken?.errors) {
                if (refreshToken.token) {

                    if (user.authenticated && refreshToken.subscribed !== null) {
                        dispatch(setUser({
                            ...user,
                            subscribed: refreshToken.subscribed,
                            paid: refreshToken.paid
                        }))
                    }
                    sessionStorage.setItem(AUTH_TOKEN, refreshToken.token)
                    if (!user.authenticated) {
                        getSelf()
                    }
                }
                if (refreshToken.expireFromNow && !refreshTimer) {
                    refreshTimer = setInterval(getRefreshToken, refreshToken.expireFromNow)
                }
            } else {
                setSnackState({open: true, message: 'Your session has ended. Please login again.'})
                handleUserLogout()
            }
        }
    })

    const [getSelf] = useLazyQuery(GET_SELF, {
        onCompleted: ({getSelf}) => {
            if (getSelf && !getSelf.errors) {
                dispatch(setUser(
                    {
                        ...user,
                        id: getSelf.id,
                        authenticated: true,
                        email: getSelf.email,
                        username: getSelf.username,
                        stripePriceId: getSelf.stripePriceId,
                        stripeCustomerId: getSelf.stripeCustomerId,
                        stripeSubscriptionId: getSelf.stripeSubscriptionId,
                        stripeCanceledAt: getSelf.stripeCanceledAt,
                        stripeSubscriptionStatus: getSelf.subscriptionStatus,
                        subscribed: getSelf.subscribed,
                        paid: getSelf.paid
                    }
                ))
            }
        }
    })
    const handleExpire = () => {
        dispatch(resetUser())
        handleUserLogout()
        setSnackState({open: true, message: 'Your session has ended. Please login again.'})
        // navigate('/')
    }

    const handleUnpaid = () => {
        setSnackState({open: true, message: 'Your subscription payment has failed. Please update your payment method.'})
    }

    useEffect(() => {
        // Fixing launch issues from previous implementation.
        if (localStorage.getItem(AUTH_TOKEN)) {
            localStorage.removeItem(AUTH_TOKEN)
        }
        if (!refreshTimer && typeof refreshTimer !== 'undefined') {
            if (refreshTokenCookie) {
                getRefreshToken({
                    variables: {
                        refreshToken: refreshTokenCookie
                    }
                })
            } else if (user.expiresFromNow) {
                refreshTimer = setInterval(handleExpire, user.expiresFromNow)
            } else if (user.authenticated === null) {
                dispatch(setUser({
                    ...user,
                    authenticated: false,
                    subscribed: false,
                    paid: false
                }))
            }
        }
    }, [getRefreshToken, refreshTokenCookie, user, handleExpire, dispatch])


    return (
        <>
            <Snackbar
                open={open}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                sx={{marginTop: 6}}
            >
                <Alert
                    onClose={handleSnackClose}
                    severity={'error'}
                    sx={{width: '100%'}}
                >
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
}


export default RefreshToken