import {createSlice} from "@reduxjs/toolkit"

const ContactSlice = createSlice({
    name: 'contact',
    initialState: {
        success: null,
        sendMessage: null,
        name: null,
        email: null,
        message: null
    },
    reducers: {
        setName: (state, action) => {
            state.name = action.payload
        },
        setEmail: (state, action) => {
            state.email = action.payload
        },
        setMessage: (state, action) => {
            state.message = action.payload
        },
        setSuccess: (state, action) => {
            state.success = action.payload
        },
        setSendMessage: (state, action) => {
            state.sendMessage = action.payload
        },
    }
})

export const {
    setName,
    setEmail,
    setMessage,
    setSuccess,
    setSendMessage
} = ContactSlice.actions

export default ContactSlice.reducer