import React, {lazy, Suspense} from "react"
import {Navigate, Route, Routes} from "react-router-dom"
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'


function AppRoutes() {
    const FallBack = () => (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "400px"
            }}
            m="auto"
        >
            <CircularProgress/>
        </Box>
    )

    const Home = (
        lazy(() => (
            import('../components/Home')
        ))
    )

    const Account = (
        lazy(() => (
            import('../components/auth/Account')
        ))
    )

    const Login = (
        lazy(() => (
            import('../components/auth/Login')
        ))
    )

    const Signup = (
        lazy(() => (
            import('../components/auth/Signup')
        ))
    )

    const Offer = (
        lazy(() => (
            import('../components/auth/Offer')
        ))
    )

    const Discount = (
        lazy(() => (
            import('../components/auth/Discount')
        ))
    )


    const WhyBp = (
        lazy(() => (
            import('../components/pages/WhyBp')
        ))
    )
    const Pricing = (
        lazy(() => (
            import('../components/pages/Pricing')
        ))
    )

    const FAQs = (
        lazy(() => (
            import('../components/pages/FAQs')
        ))
    )

    const DashboardHome = (
        lazy(() => (
            import('../components/pages/Dashboard/Home')
        ))
    )

    const SearchResults = (
        lazy(() => (
            import('../components/pages/Dashboard/SearchResults')
        ))
    )

    const Instructor = (
        lazy(() => (
            import('../components/pages/Instructor/Home')
        ))
    )

    const InstructorList = (
        lazy(() => (
            import('../components/pages/Instructor/List')
        ))
    )

    const Videos = (
        lazy(() => (
            import('../components/pages/Video')
        ))
    )

    const Favorites = (
        lazy(() => (
            import('../components/pages/Library/Favorites')
        ))
    )

    const Recent = (
        lazy(() => (
            import('../components/pages/Library/RecentlyViewed')
        ))
    )

    const Subscribe = (
        lazy(() => (
            import('../components/auth/Subscribe')
        ))
    )

    const Payment = (
        lazy(() => (
            import('../components/auth/PaymentIntent')
        ))
    )


    const Live = (
        lazy(() => (
            import('../components/pages/Live')
        ))
    )
    const LiveSignup = (
        lazy(() => (
            import('../components/pages/Live/Signup')
        ))
    )

    const Equipment = (
        lazy(() => (
            import('../components/pages/Equipment')
        ))
    )

    const ProductList = (
        lazy(() => (
            import('../components/product/ProductList')
        ))
    )

    const VideoTypes = (
        lazy(() => (
            import('../components/pages/Dashboard/VideoTypes')
        ))
    )

    const About = (
          lazy(() => (
            import('../components/pages/About')
        ))
    )

    // const Contact = (
    //       lazy(() => (
    //         import('../components/pages/Contact')
    //     ))
    // )

    const Terms = (
          lazy(() => (
            import('../components/pages/legal/Terms')
        ))
    )

    const Privacy = (
          lazy(() => (
            import('../components/pages/legal/Privacy')
        ))
    )

    const ForgotPassword = (
        lazy(() => (
            import('../components/auth/ForgotPassword')
        ))
    )

    const ResetPassword = (
        lazy(() => (
            import('../components/auth/ResetPassword')
        ))
    )


    return (
        <Suspense fallback={<FallBack/>}>
            <Routes>
                <Route path='/' exact element={<Home/>}/>
                <Route path='/dashboard' element={<DashboardHome/>}/>
                <Route path='/dashboard/:videoType' element={<VideoTypes/>}/>
                <Route path='/live' element={<Live/>}/>
                <Route path='/live/signup/:contentId' element={<LiveSignup/>}/>
                <Route path='/login' element={<Login/>}/>
                <Route path='/account' element={<Account/>}/>
                <Route path='/account/subscription' element={<Account/>}/>
                <Route path='/account/subscription/manage' element={<ProductList/>}/>
                <Route path='/signup' element={<Signup/>}/>
                <Route path='/offer/:offername' element={<Offer/>}/>
                <Route path='/offer/:offername/:couponcode' element={<Offer/>}/>
                <Route path='/discount/:offername/:couponcode' element={<Discount/>}/>
                <Route path='/why-bp' element={<WhyBp/>}/>
                <Route path='/search' element={<SearchResults/>}/>
                <Route path='/library/favorites' element={<Favorites/>}/>
                <Route path='/library/recent' element={<Recent/>}/>
                <Route path='/instructors/:instructorId' element={<Instructor/>}/>
                <Route path='/instructors/:instructorId/:videoType' element={<InstructorList/>}/>
                <Route path='/instructors' element={<Instructor/>}/>
                <Route path='/videos/:videoId' element={<Videos/>}/>
                <Route path='/videos' element={<Videos/>}/>
                <Route path='/subscribe' element={<Subscribe/>}/>
                <Route path='/trial' element={<Subscribe/>}/>
                <Route path='/payment' element={<Payment/>}/>
                <Route path='/pricing' element={<Pricing/>}/>
                <Route path='/faqs' element={<FAQs/>}/>
                <Route path='/equipment' element={<Equipment/>}/>
                <Route path='/about' element={<About/>}/>
                {/*<Route path='/contact-us' element={<Contact/>}/>*/}
                <Route path='/terms-and-conditions' element={<Terms />} />
                <Route path='/privacy-policy' element={<Privacy />} />
                <Route path='/forgot-password' element={<Navigate replace to={'/reset-password'} /> } />
                <Route path='/reset-password' element={<ForgotPassword />} />
                <Route path='/reset-password/:token' element={<ResetPassword />} />
                <Route path='/special-offer' element={<Navigate replace to={'/offer/special-offer'} /> } />
                <Route path='/annual-special-offer' element={<Navigate replace to={'/offer/annual-special-offer'} /> } />
                <Route path='/monthly-subscription' element={<Navigate replace to={'/offer/monthly-subscription'} /> } />
            </Routes>
        </Suspense>
    )
}

export default AppRoutes

