import './assets/css/App.css'
import {
    ApolloClient,
    ApolloProvider,
    HttpLink,
    InMemoryCache,
    from,
} from "@apollo/client"
import {setContext} from "@apollo/client/link/context"
import {onError} from "@apollo/client/link/error"
import Main from './components/scaffolding/Main'
import {AUTH_TOKEN} from "./config/constants"
import {ThemeProvider} from '@mui/material/styles'
import {theme} from './theme'
import RefreshToken from "./components/auth/RefreshToken";
import {useSelector} from "react-redux";
import {useEffect} from "react";

const errorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({message, locations, path}) =>
            console.error(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
        )

    if (networkError) console.error(`[Network error]: ${networkError}`)
})


const standardLink = new HttpLink({uri: process.env.REACT_APP_GRAPHQL_URL})
const authLink = setContext((_, {headers}) => {
    const token = sessionStorage.getItem(AUTH_TOKEN) ? sessionStorage.getItem(AUTH_TOKEN) : null
    return {
        headers: {
            ...headers,
            ...(token) && {Authorization: `Bearer ${token}`},
        },
    }
})


const link = from([errorLink, authLink, standardLink])

const client = new ApolloClient({
    cache: new InMemoryCache({
        addTypename: false,
    }),
    link,
})


function App() {

    const user = useSelector(state => state.global.user)

    useEffect(() => {
        const gatsbyPromo = document.getElementById('gatsbyPromo')
        if (user.subscribed === false) {
            const script = document.createElement('script')
            script.id = 'gatsbyPromo'
            script.src = "https://gatsby-statics.gatsby.tech/bodypole/script.min.js"
            script.async = true
            document.body.appendChild(script)
        } else if (gatsbyPromo) {
            gatsbyPromo.remove()
            const flyout = document.getElementById('GatsbyCTAInsta')
            flyout.remove()
        }

    }, [user])

    return (
        <>
            <ApolloProvider client={client}>
                <ThemeProvider theme={theme}>
                    <RefreshToken/>
                    <Main/>
                </ThemeProvider>
            </ApolloProvider>
        </>
    )
}

export default App
