import React from 'react'
import '../../../assets/css/App.css'
import Grid from "@mui/material/Grid"
import Box from '@mui/material/Box'
import logo from '../../../assets/images/bp-online-logo-footer.png'
import fb from '../../../assets/images/icons/facebook.png'
import ig from '../../../assets/images/icons/instagram.png'
import tw from '../../../assets/images/icons/twitter.png'

import {Link, useLocation} from "react-router-dom"
import {useSelector} from "react-redux"

function Footer() {
    const location = useLocation()
    const dashboardList = ['search', 'dashboard', 'videos', 'favorites', 'recent', 'library', 'instructors']
    const path = location.pathname.toLowerCase()
    const isDashboard = dashboardList.filter(item => path.indexOf(item) > -1)
    const padLeft = isDashboard.length > 0 ? 500 : 0
    const user = useSelector(state => state.global.user)
    const padBottom = (user.subscribed) ? 0 : 12

    return (
        <Box id="footer" component='footer' sx={{pb: padBottom, width: '100%', paddingTop: '4rem', paddingLeft: {xs: 0, md: `${padLeft}px`}}}>
            <Grid container>
                {!user.authenticated &&
                    <><Grid item xs={12} md={6}>
                        <Box pl={3} mb={4}><strong>SIGN UP TO RECEIVE SPECIAL UPDATES AND OFFERS</strong>
                            <div>
                                <script type='text/javascript'
                                        src='https://bodyandpole.activehosted.com/f/embed.php?id=1&617B4917128D8'></script>
                            </div>
                        </Box>
                    </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container>
                                <Grid item xs={12} md={9}>
                                    <Box sx={{
                                        lineHeight: 0,
                                        marginTop: '14px',
                                        marginBottom: '17px',
                                        textAlign: {xs: "center", md: "right"}
                                    }}>
                                        <h4>CONNECT WITH US</h4></Box>
                                    <Box sx={{display: "flex", justifyContent: {xs: 'center', md: "flex-end"}}}>
                                        <ul className="menu-list icons">
                                            <li style={{marginRight: '16px'}}>
                                                <a target="_blank" rel="noopener noreferrer"
                                                   href="https://www.facebook.com/bodyandpoleNYC/">
                                                    <img alt={`Facebook`} src={fb}/>
                                                </a>
                                            </li>
                                            <li style={{marginRight: '16px'}}>
                                                <a target="_blank" href="https://twitter.com/bodyandpole"
                                                   rel="noopener noreferrer">
                                                    <img alt={`Twitter`} src={tw}/>
                                                </a>
                                            </li>
                                            <li>
                                                <a target="_blank" href="https://www.instagram.com/bodyandpole/"
                                                   rel="noopener noreferrer">
                                                    <img alt={`Instagram`} src={ig}/>
                                                </a>
                                            </li>
                                        </ul>
                                    </Box></Grid>
                                <Grid item xs={12} sm={3}>
                                    <Box sx={{
                                        display: {xs: 'none', md: 'block'},
                                        width: {md: '115px'},
                                        marginRight: 0,
                                        marginLeft: "auto"
                                    }}>
                                        <a href="/">
                                            <img style={{display: "block"}}
                                                 src={logo}
                                                 alt="Body and Pole Online"/>
                                        </a>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid></>
                }
            </Grid>
            <Grid container style={{
                borderTop: "1px solid #ededed",
                marginBottom: '2.5em',
                marginTop: '2rem',
                paddingTop: '2rem'
            }}>
                <Grid item xs={12} md={6} className="uppercase text-small text-gray">
                    <Box pl={3}>
                        &copy; 2023 BODY &amp; POLE. ALL RIGHTS RESERVED. <br />
                       <Link to="/terms-and-conditions"><strong>TERMS AND CONDITIONS.</strong>
                    </Link> &nbsp;
                        <Link to="/privacy-policy">
                            <strong>PRIVACY POLICY.</strong>
                        </Link>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{display: 'flex', justifyContent: 'end', pt: {xs: 4, md: 0}}}>
                        <ul className="menu-list clean uppercase" style={{width: "420px"}}>
                            <li>
                                <Link to="/about">About</Link>
                            </li>
                            <li>
                                <Link to="/live">Live</Link>
                            </li>
                            <li>
                                <Link to="/faqs">FAQs</Link>
                            </li>
                            <li>
                                <a href={'mailto:bponline@bodyandpole.com'}>Contact</a>
                            </li>
                        </ul>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Footer