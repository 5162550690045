import {gql} from "@apollo/client";

export const GET_AUTOCOMPLETE = gql`
    query get_autocomplete($q: String!) {
        autocomplete(q: $q) {
            title
            type
            id
        }
    }
`