import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'

import App from './App'
import {BrowserRouter as Router} from "react-router-dom"
import Seo from "./components/html/Seo"
import store from "./store"
import {Provider} from 'react-redux'
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3"


ReactDOM.render(
    <Provider store={store}>
        {/*<GoogleReCaptchaProvider reCaptchaKey={'6LfkCeUiAAAAAOEXJY-5G1SXdXUN7mfBQlfyN1i_'}>*/}
        <Router>
            <Seo />
            <App/>
        </Router>
        {/*</GoogleReCaptchaProvider>*/}
    </Provider>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()