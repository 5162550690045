import {createSlice} from "@reduxjs/toolkit"


const MessageBarSlice = createSlice({
    name: "messagebar",
    initialState: {
        open: false,

    },
    reducers: {

    }
})

export const {
    setEmail,
    setPassword,
    setError,
    setAlertOpen,
    setShowPassword,
    clearMessages
} = MessageBarSlice.actions

export default MessageBarSlice.reducer