import React from 'react'
import {handleUserLogout} from "../../auth/RefreshToken"
import Box from '@mui/material/Box'
import logo from '../../../assets/images/bp-online-logo-footer.png'
import Menu from "@mui/material/Menu"
import Avatar from '@mui/material/Avatar'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import MenuIcon from '@mui/icons-material/Menu'
import Logout from '@mui/icons-material/Logout'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import {Drawer, List, ListItemText, ListItemButton, Modal} from "@mui/material"
import {Link, useLocation, useNavigate} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {setLoginModal, resetUser} from "../../../slice"
import Login from "../../auth/Login"
import Button from "@mui/material/Button"
import SearchInput from "../../scaffolding/Videos/SearchInput"

function Header(props) {

    const {window} = props
    const navigate = useNavigate()
    const [mobileOpen, setMobileOpen] = React.useState(false)
    const dispatch = useDispatch()
    const loginModal = useSelector(state => state.global.loginModal)
    const user = useSelector(state => state.global.user)
    const subscribed = user.subscribed
    const paid = user.paid
    const location = useLocation()
    const dashboardList = ['search', 'dashboard', 'videos', 'favorites', 'recent', 'library']
    const path = location.pathname.toLowerCase()
    const isDashboard = dashboardList.filter(item => path.indexOf(item) > -1)

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    };
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null)
    const menuOpen = Boolean(menuAnchorEl)

    const handleProfileClick = (event) => {
        setMenuAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setMenuAnchorEl(null)
    }

    const container = window !== undefined ? () => window().document.body : undefined
    const drawerWidth = 320

    const handleMenuClick = (where) => {
        setMobileOpen(false)
        navigate(where)
    }

    const handleLogout = () => {
        handleUserLogout()
        dispatch(resetUser())
        navigate('/')
    }

    return (
        <AppBar>
            <Modal
                open={loginModal}
                onClose={() => dispatch(setLoginModal(false))}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Login/>
                </Box>
            </Modal>
            <Toolbar style={{backgroundColor: "#fffff"}} sx={{justifyContent: {xs: 'space-between'}}}>
                <Box sx={{pt: 1, position: {md: 'absolute'}}}>
                    <Link to="/">
                        <img src={logo} alt='Body and Pole Online' style={{width: "50px"}}/>
                    </Link>
                </Box>
                <Box component={`nav`}
                     sx={{
                         display: {xs: 'none', md: 'flex'},
                         flexGrow: 1,
                         justifyContent: "center",
                         "& div": {marginRight: "2rem"}
                     }}>
                    {subscribed !== true &&
                        <Box>
                            <Link to="/why-bp">Why BPOnline?</Link>
                        </Box>
                    }
                    {(subscribed === true) ?
                        <Box><Link to="/faqs">FAQs</Link></Box>
                        :
                        <Box>
                            {(user.stripSubscriptionStatus !== 'PAST_DUE') &&
                            <Link to="/pricing">Pricing</Link>
                        }
                        </Box>

                    }
                    <Box><Link to='/live'>Live</Link></Box>

                    <Box>
                        <Link to="/dashboard">Browse Classes</Link>
                    </Box>
                </Box>
                {(user.authenticated !== true) &&
                    <Box component={`nav`} sx={{
                        display: {xs: 'none', md: 'flex'},
                        position: 'absolute',
                        right: 0,
                        "& div": {marginRight: "2rem"}
                    }}
                         mr={3}>
                        <div>
                            <Button variant='text' onClick={() => {
                                dispatch(setLoginModal(true))
                            }}>Login</Button>
                        </div>
                        <div>
                            <Button variant='text' onClick={() => {
                                navigate('/signup')
                            }}>Sign up</Button>
                        </div>
                    </Box>
                }
                {(isDashboard.length > 0) &&
                    <Box sx={{width: {xs: '68%'}, mx: 'auto', display: {xs: 'block', md: 'none'}}}>
                        <SearchInput size={'small'}/>
                    </Box>
                }

                <IconButton
                    edge="end"
                    onClick={handleDrawerToggle}
                    sx={{display: {xs: 'block', md: 'none'}}}
                    name={'Menu'}
                >
                    <MenuIcon/>
                </IconButton>

                {(user.authenticated === true) &&
                    <Box mr={2} sx={{
                        display: {xs: 'none', md: 'flex'},
                        marginLeft: 'auto',
                        position: 'absolute',
                        right: '1rem'
                    }}>
                        <Tooltip title="Account settings">
                            <Button onClick={handleProfileClick} size="small" sx={{ml: 2}}
                                    endIcon={<AccountBoxIcon sx={{width: 32, height: 32}}/>}>
                                {user.username}
                            </Button>
                        </Tooltip>
                        <Menu
                            anchorEl={menuAnchorEl}
                            open={menuOpen}
                            onClose={handleMenuClose}
                            onClick={handleMenuClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiPersonPin-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{horizontal: 'right', vertical: 'top'}}
                            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                        >
                            <MenuItem onClick={() => {
                                navigate('/account')
                            }}>
                                <ListItemIcon>
                                    <Avatar fontSize='small' sx={{width: 24, height: 24}}/>
                                </ListItemIcon> My account
                            </MenuItem>
                            <Divider/>
                            <MenuItem onClick={handleLogout}>
                                <ListItemIcon>
                                    <Logout fontSize="small"/>
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        </Menu>
                    </Box>
                }
            </Toolbar>
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: {xs: 'block', md: 'none'},
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                }}
            >
                <Box sx={{pl: 2, pt: 1}}>
                    <Link to="/">
                        <img src={logo} alt='Body and Pole Online' style={{width: "50px"}}/>
                    </Link>
                </Box>
                <List>
                    <ListItemButton onClick={() => {
                        handleMenuClick('/why-bp')
                    }}>
                        <ListItemText primary={`Why BPOnline?`}/>
                    </ListItemButton>

                    {subscribed === true && paid === true ?
                        <ListItemButton onClick={() => {
                            handleMenuClick('/faqs')
                        }}>
                            <ListItemText primary={`FAQs`}/>
                        </ListItemButton>
                        :
                        <ListItemButton onClick={() => {
                            handleMenuClick('/pricing')
                        }}>
                            <ListItemText primary={`Pricing`}/>
                        </ListItemButton>
                    }
                    <ListItemButton onClick={() => {
                        handleMenuClick('/live')
                    }}>
                        <ListItemText primary={`Live`}/>
                    </ListItemButton>
                    <ListItemButton onClick={() => {
                        handleMenuClick('/dashboard')
                    }}>
                        <ListItemText primary={`Browse Classes`}/>
                    </ListItemButton>
                    <Divider sx={{my: 2}}/>

                    {
                        user.authenticated ?
                            <>
                                <ListItemButton onClick={() => {
                                    navigate('/account')
                                }}>
                                    <ListItemIcon><Avatar/></ListItemIcon>
                                    <ListItemText primary={`My account`}/>
                                </ListItemButton>
                                <Divider/>

                                <ListItemButton onClick={handleLogout}>
                                    <ListItemIcon>
                                        <Logout fontSize="small"/>
                                    </ListItemIcon>
                                    <ListItemText primary={`Logout`}/>
                                </ListItemButton></>

                            :
                            <>
                                <ListItemButton onClick={() => {
                                    navigate('/login')
                                }}>
                                    <ListItemText primary={`Login`}/>
                                </ListItemButton>
                                <ListItemButton onClick={() => {
                                    navigate('/signup')
                                }}>
                                    <ListItemText primary={`Sign up`}/>
                                </ListItemButton>
                            </>
                    }
                    <Divider sx={{my: 2}}/>
                </List>
            </Drawer>
        </AppBar>
    )
}

export default Header