import React from 'react'
import PropTypes from "prop-types"
import {Helmet} from "react-helmet"

function Seo(props) {
    const {title} = props
    const {description} = props || null
    const {noIndex} = props || false
    // const title = 'The Title'
    // const description = 'The description'
    const static_description = 'Get unlimited access to our online pole, aerial, and floor classes, designed to give you the best of our in-studio expertise, for everyday use at home.'
    return (
        <Helmet>
            <title>BodyAndPole Online | {(title ? title : 'Pole, Floor, and Aerial Dance Classes Online')}</title>
            <meta name='description'
                  content={(description) ? description : static_description}/>
            <meta property='og:local' content='en_US'/>
            <meta property="og:type" content="website"/>
            <meta property="og:description"
                  content={(description) ? description : static_description}/>
            <meta property="og:url" content="https://www.bodyandpoleonline.com/"/>
            <meta property="og:site_name" content="Body &amp; Pole Online"/>

            <meta name="google-site-verification" content="yujN-5SUonpVQXidi4o5WbkODNYYzYvPLR1cIBrMX6k"/>
            {(noIndex === true) &&
                <meta name="robots" content="noindex"/>
            }
        </Helmet>
    )
}

Seo.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    noIndex: PropTypes.bool
}

export default Seo