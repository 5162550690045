import {createSlice} from "@reduxjs/toolkit"

const DrawerSlice = createSlice({
    name: 'drawer',
    initialState: {
        drawerOpen: false,
        drawerItems: {
            library: true,
            instructors: false,
            classes: true,
            techniques: false
        },
        selectedClassType: null,
        classes: {
            pole: false,
            floor: false,
            aerial: false
        },
        selections: {
            pole: {
                poleLevel1: false,
                poleLevel2: false,
                poleLevel3: false,
                live: false
            },
            floor: {
                invertible: false,
                core: false,
                liquidMotion: false,
                flex: false,
                live: false,
            },
            aerial: {
                silkConditioning: false,
                hoopConditioning: false
            }
        }
    },
    reducers: {
        setDrawerOpen: (state, action) => {
            state.drawerOpen = action.payload
        },
        setDrawerItem: (state, action) => {
            state.drawerItems = {
                ...state.drawerItems,
                [action.payload.item]: !action.payload.value
            }
        },
        setDrawerChecked: (state, action) => {
            state.drawerChecked = {
                ...state.drawerChecked,
                [action.payload.item]: !action.payload.value
            }
        },
        unsetGroupSelection: (state) => {
            state.selectedClassType = false
        },
        setGroupSelection: (state, action) => {
            /*
            Selects children of the main radio buttons, while deselecting children of non-selected children.
             */
            state.selectedClassType = null
            for (const [selection, v] of Object.entries(state.selections)) {
                if (selection === action.payload) {
                    state.selectedClassType = action.payload
                    for (const [key, value] of Object.entries(state.selections[selection])) {
                        state.selections[selection][key] = true
                    }
                } else {
                    for (const [key, value] of Object.entries(state.selections[selection])) {
                        state.selections[selection][key] = false
                    }
                }
            }

            for (const [key, value] of Object.entries(state.classes)) {
                state.classes[key] = (key === action.payload)
            }
        }


    }

})

export const {
    setDrawerOpen,
    setDrawerItem,
    setGroupSelection,
    unsetGroupSelection
} = DrawerSlice.actions

export default DrawerSlice.reducer