import {createSlice} from "@reduxjs/toolkit"


const ProductDialogSlice = createSlice({
        name: "productDialog",
        initialState: {
            open: false,
            priceId: ''
        },
        reducers: {
            setOpen: (state, action) => {
                state.open = action.payload
            },
            setPriceId: (state, action) => {
                state.priceId = action.payload
            }
        }
    }
)

export const {
    setOpen,
    setPriceId,
} = ProductDialogSlice.actions

export default ProductDialogSlice.reducer